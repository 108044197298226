
import { ClaimProcessItem } from '@/store/modules/company-tabs/claim-process';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ClaimProcessWidget extends Vue {
  get claimData(): ClaimProcessItem {
    return this.$store.getters.claimCurrentData;
  }

  convertDisplayDate(date: Date | undefined) {
    if (date) {
      return date.toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
    }
    return 'In process';
  }

  get displayLossDate(): string {
    return this.convertDisplayDate(this.claimData.ConfirmationOfLossDate);
  }

  get displayPaidDate(): string {
    return this.convertDisplayDate(this.claimData.ClaimPaidDate);
  }
}
