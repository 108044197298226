
import { Chart } from '@/libs/chart-lib';
import { Component, Vue } from 'vue-property-decorator';
import { createChart, ChartData } from './loss-confirmation-chart-config';
import { MarketChangeItem } from '@/store/modules/company-tabs/market-change';
import { roundDayDate } from './utils';

@Component
export default class LossConfirmationChart extends Vue {
  private chart?: Chart;

  mounted() {
    if (this.startDate && this.endDate && this.priceArr && this.secondTriggerRange) {
      this.chart = createChart({
        container: this.$refs.lossChartContainer as HTMLElement,
        startDate: this.startDate,
        endDate: this.endDate,
        priceList: this.priceArr,
        secondTriggerRange: this.secondTriggerRange,
      });
    }
  }

  beforeDestroy() {
    delete this.chart;
  }

  get triggerData(): MarketChangeItem {
    return this.$store.getters.companyMarketChange;
  }

  get shouldMoveRange() {
    const secondTriggerDate = this.triggerData.SecondTriggerPeriodEndDate;
    if (secondTriggerDate) {
      const endDate = roundDayDate(secondTriggerDate);
      const lastPriceDate = roundDayDate(this.priceArr.slice(-1)[0].date);
      return lastPriceDate.getTime() >= endDate.getTime();
    }
    return false;
  }

  get startDate(): MarketChangeItem['PercentPeriodStartDate'] {
    const date = this.triggerData.PercentPeriodStartDate;
    if (date) {
      const copyDate = new Date(date.getTime());
      if (this.shouldMoveRange) {
        copyDate.setDate(copyDate.getDate() + 15);
      }
      return roundDayDate(copyDate);
    }
    return undefined;
  }

  get endDate(): MarketChangeItem['SecondTriggerPeriodEndDate'] {
    const date = this.triggerData.SecondTriggerPeriodEndDate;
    if (date) {
      const copyDate = new Date(date.getTime());
      if (this.shouldMoveRange) {
        copyDate.setDate(copyDate.getDate() + 15);
      }
      return roundDayDate(copyDate);
    }
    return undefined;
  }

  get priceArr(): ChartData['priceList'] {
    const list: MarketChangeItem[] = this.$store.getters.companyMarketChangeList;
    return list.map(({ Date, PercentChange }) => {
      return {
        date: roundDayDate(Date),
        value: PercentChange / 100,
      };
    });
  }

  get secondTriggerRange(): [Date, Date] | undefined {
    const { SecondTriggerPeriodStartDate, SecondTriggerPeriodEndDate } = this.triggerData;
    if (SecondTriggerPeriodStartDate && SecondTriggerPeriodEndDate)
      return [roundDayDate(SecondTriggerPeriodStartDate), roundDayDate(SecondTriggerPeriodEndDate)];
    return undefined;
  }
}
