import { Chart } from '@/libs/chart-lib/index';
import { getMonthDayLabelText } from './utils';

const canvasPaddings = [20, 40 + 15, 38 + 21, 45 * 0.7];

export interface ChartData {
  container: HTMLElement;
  startDate: Date;
  endDate: Date;
  priceList: Array<{ date: Date; value: number }>;
  secondTriggerRange: [Date, Date];
}

export function createChart({
  container,
  startDate,
  endDate,
  priceList,
  secondTriggerRange,
}: ChartData) {
  const chart: Chart = new Chart(container, [startDate.getTime(), endDate.getTime()], [-0.3, 0.1]);

  const { xAxis, yAxis } = chart;

  xAxis.ticks.label.setOptions(true, '#000000', 'bottom', 13 + 6 + 9 * 1.5, [
    '12',
    'JetBrains Mono',
  ]);
  xAxis.ticks.setOptions(true, 'timestampDayStart');
  xAxis.ticks.settickDrawOptionsPartial({
    linewidth: 1,
    tickSize: -6,
    offset: { x: 0, y: 13 },
    color: '#000000',
  });
  xAxis.grid.setOptions(true, '#0000001A', 1, [1, 0]);

  // plots
  chart
    .addPlot('sideValues', 'side_text')
    .label.setOptions(true, '#000000', 'left', -11 - 4, ['12', 'JetBrains Mono'])
    .setCenterX(0);
  chart
    .addPlot('sideValues_red', 'side_text')
    .label.setOptions(true, '#FF1515', 'left', -11 - 4, ['12', 'JetBrains Mono'])
    .setCenterX(0);

  const zeroLinePlot = chart.addPlot(
    'black_static_line',
    'line_horizontal',
    1,
    '#00000080',
    [1, 0],
    'round'
  );
  zeroLinePlot.setOptionsPartially({ vpOffset: { x1: -15, x2: 11, y1: 0, y2: 0 } });

  const redLinePlot = chart.addPlot(
    'red_static_line',
    'line_horizontal',
    1,
    '#FF0000',
    [1, 0],
    'round'
  );
  redLinePlot.setOptionsPartially({ vpOffset: { x1: -15, x2: 11, y1: 0, y2: 0 } });

  chart.addPlot('priceCircle', 'dotted').setOptionsPartially({
    brushColor: '#CCCCCC',
    mainSize: 4,
    lineWidth: 0,
    lineColor: 'transparent',
  });

  chart.addPlot('blue_area', 'line_area_vertical').setOptionsPartially({
    lineColor: 'transparent',
    brushColor: '#6266F3',
    opacity: 0.05,
  });

  chart.addPlot('blue_static_line', 'line_vertical', 3, '#6266F3', [1, 0], 'round');

  const lastValue = priceList.map(({ value }) => value).slice(-1)[0];
  const lastColor = lastValue >= 0 ? '#3D894C' : '#E42929';

  chart.addPlot('priceCircle_red', 'dotted').setOptionsPartially({
    brushColor: lastColor,
    mainSize: 4 + 1.5,
    lineWidth: 3,
    lineColor: '#F9F9F9',
  });

  const lastLine = chart.addPlot(
    'red_static_line_last',
    'line_vertical',
    3,
    lastColor + '80',
    [1, 0],
    'round'
  );
  lastLine.setOptionsPartially({ vpOffset: { x1: 0, x2: 0, y1: 0, y2: 13 } });

  chart.addPlot('red_triangle_cursor', 'triangle_cursor_x').setOptionsPartially({
    brushColor: lastColor,
    size: {
      x: 13 * 0.5,
      y: 9,
    },
    offset: { x: 0, y: 13 },
  });

  chart
    .addPlot('mainValues', 'text_line')
    .label.setOptions(true, '#FFFFFF', 'top', 11 + 4 + (21 - 12) / 2, ['12', 'JetBrains Mono'])
    .setBackgroundRectangle({
      padding: [12, (21 - 12) / 2 + 4, 12, (21 - 12) / 2 + 4],
      backgroundColor: lastColor,
      radius: 8,
      offset: { x: 0, y: -1 },
    });

  chart
    .addPlot('x_axis_date_label', 'label_x')
    .label.setOptions(true, '#000000', 'bottom', 13 + 6 + 9 * 1.5, ['12', 'JetBrains Mono'])
    .setBackgroundRectangle({
      padding: [16, 8, 16, 8],
      backgroundColor: '#EEEEEE',
      radius: 16,
      offset: { x: 0, y: -1 },
    });

  chart
    .addPlot('x_axis_text_vp_top', 'label_x_top')
    .label.setOptions(true, '#00000080', 'top', 5, ['12', 'JetBrains Mono']);

  // data
  const redLinesArr = [-0.15, -0.2, -0.25].map((value) => {
    return {
      x: endDate.getTime(),
      y: value,
      text: `${value * 100}%`,
    };
  });

  const areaDates = [];
  if (
    secondTriggerRange[0].getTime() < startDate.getTime() &&
    secondTriggerRange[1].getTime() > startDate.getTime()
  ) {
    areaDates.push(startDate.getTime());
  } else {
    areaDates.push(secondTriggerRange[0].getTime());
  }

  if (
    secondTriggerRange[1].getTime() > endDate.getTime() &&
    secondTriggerRange[0].getTime() < endDate.getTime()
  ) {
    areaDates.push(endDate.getTime());
  } else {
    areaDates.push(secondTriggerRange[1].getTime());
  }

  // series
  chart
    .addSeries('horizontal_zero_line', [[endDate.getTime()], [0]], ['0'])
    .setPlotsIds('black_static_line', 'sideValues');

  chart
    .addSeries(
      'horizontal_static_lines',
      [redLinesArr.map(({ x }) => x), redLinesArr.map(({ y }) => y)],
      redLinesArr.map(({ text }) => text)
    )
    .setPlotsIds('red_static_line', 'sideValues_red');

  chart
    .addSeries('price_chart', [
      priceList.map(({ date }) => date.getTime()),
      priceList.map(({ value }) => value),
    ])
    .setPlotsIds('priceCircle');

  chart
    .addSeries('second_trigger_area', [areaDates, areaDates.map(() => 0)])
    .setPlotsIds('blue_area');

  chart
    .addSeries(
      'second_trigger',
      [secondTriggerRange.map((date) => date.getTime()), secondTriggerRange.map(() => 0)],
      ['T+0', 'T+21']
    )
    .setPlotsIds('blue_static_line', 'x_axis_text_vp_top');

  chart
    .addSeries(
      'price_last',
      [
        priceList.map(({ date }) => date.getTime()).slice(-1),
        priceList.map(({ value }) => value).slice(-1),
      ],
      priceList.map(({ value }) => `${(value * 100).toFixed(0)}%`).slice(-1)
    )
    .setPlotsIds('red_triangle_cursor', 'red_static_line_last', 'priceCircle_red', 'mainValues');

  chart
    .addSeries(
      'price_last_date',
      [
        priceList.map(({ date }) => date.getTime()).slice(-1),
        priceList.map(({ value }) => value).slice(-1),
      ],
      priceList.map(({ date }) => getMonthDayLabelText(date).toUpperCase()).slice(-1)
    )
    .setPlotsIds('x_axis_date_label');

  yAxis.display = false;
  yAxis.ticks.label.setOptions(false);

  chart.setCanvasPaddings(...canvasPaddings);
  chart.refresh();
  return chart;
}
