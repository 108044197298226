
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CorporateWidget extends Vue {
  @Prop() StartDate!: Date;
  @Prop() EndDate!: Date;
  @Prop() Category!: string;
  @Prop() Source!: string;
  @Prop() Summary!: string;

  formatDate(date: Date) {
    return new Date(date).toLocaleString('us', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  }
}
