
import { Component, Prop, Provide, Emit, Vue } from 'vue-property-decorator';

@Component
export default class RadioGroup extends Vue {
  @Prop() list!: Array<{ text: string; value: any }>;
  @Prop() value!: any;
  @Provide() selected: any = this.value;

  @Emit()
  onValueChange() {
    return this.selected;
  }
}
