
import { LossProbabilityItem } from '@/store/modules/company-tabs/loss-probability';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FirstTriggerWidget extends Vue {
  @Prop({ default: false }) hasData!: boolean;
  @Prop({ default: '#000000' }) labelColor!: string;
  @Prop() status!: LossProbabilityItem['Status'];
  @Prop() relativeOdds!: LossProbabilityItem['RelativeOdds'];
  @Prop() date!: LossProbabilityItem['ChTimestamp'];
  @Prop({ default: false }) selected!: boolean;

  get displayDate() {
    if (!this.date) return '-';
    const dateArr = this.date
      .toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })
      .split(' ');
    return `${dateArr[1]}, ${dateArr[0]} ${dateArr[2]}`;
  }
}
