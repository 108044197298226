
import { LossProbabilityItem } from '@/store/modules/company-tabs/loss-probability';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class StatusWidget extends Vue {
  @Prop({ default: false }) hasData!: boolean;
  @Prop({ default: '#000000' }) labelColor!: string;
  @Prop() status!: LossProbabilityItem['Status'];
  @Prop() relativeOdds!: LossProbabilityItem['RelativeOdds'];
  @Prop() rank!: LossProbabilityItem['Rank'];

  get resultText() {
    if (this.relativeOdds > 1)
      return 'Constant malicious activity is having a significant impact on your business productivity.';
    if (this.relativeOdds >= -1 && this.relativeOdds <= -1) return '';
    if (this.relativeOdds < -1)
      return 'Constant malicious activity is not having a significant impact on your business productivity.';
    return '';
  }
}
