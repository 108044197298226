import chroma from 'chroma-js';

const opacityColor = {
  0.5: '80',
  0.4: '66',
  0.3: '4D',
} as const;

export const lossColorScheme: { color: string; opacity?: keyof typeof opacityColor }[] = [
  { color: '#3D894E' },
  { color: '#2D876D', opacity: 0.5 },
  { color: '#25867D', opacity: 0.3 },
  { color: '#1C858D', opacity: 0.4 },
  { color: '#14849C', opacity: 0.5 },
  { color: '#1F75CB', opacity: 0.5 },
  { color: '#2C69F3' },
  { color: '#9486AF', opacity: 0.5 },
  { color: '#EE9E74', opacity: 0.5 },
  { color: '#EC8564', opacity: 0.5 },
  { color: '#EA6D55', opacity: 0.5 },
  { color: '#E85646', opacity: 0.5 },
  { color: '#E42929' },
];

function getColorWithOpacity(color: string, opacity?: keyof typeof opacityColor) {
  if (opacity) {
    return `${color}${opacityColor[opacity]}`;
  }
  return color;
}

export function getColorByValue(min: number, max: number, value: number, withOpacity?: boolean) {
  const f = chroma
    .scale(
      lossColorScheme.map(({ color, opacity }) =>
        withOpacity ? getColorWithOpacity(color, opacity) : color
      )
    )
    .domain(lossColorScheme.map((_el, ind) => ind));

  let relativeVal;
  if (value < 0) {
    relativeVal = (((lossColorScheme.length - 1) / 2) * Math.abs(min - value)) / Math.abs(min);
  } else {
    relativeVal =
      (lossColorScheme.length - 1) / 2 +
      (((lossColorScheme.length - 1) / 2) * Math.abs(value)) / Math.abs(max);
  }
  return f(relativeVal).hex();
}

export function getColorSchemeValued(min: number, max: number, withOpacity?: boolean) {
  const leftCount = Math.floor(lossColorScheme.length / 2);
  const rightCount = lossColorScheme.length - leftCount;
  const leftPartColors = lossColorScheme.slice(0, leftCount);
  const minHalfArr = leftPartColors.map(({ color, opacity }, ind) => {
    return {
      value: min + ((Math.abs(min) - 1) / leftCount) * ind,
      color: withOpacity ? getColorWithOpacity(color, opacity) : color,
    };
  });
  const rightPartColors = lossColorScheme.slice(-rightCount);
  const maxHalfArr = rightPartColors.map(({ color, opacity }, ind) => {
    return {
      value: 1 + ((Math.abs(max) - 1) / (rightCount - 1)) * ind,
      color: withOpacity ? getColorWithOpacity(color, opacity) : color,
    };
  });
  return [...minHalfArr, ...maxHalfArr];
}

export function getColorByClosestValue(
  min: number,
  max: number,
  targetValue: number,
  withOpacity?: boolean
): string {
  const arr = getColorSchemeValued(min, max, withOpacity);
  const dif = arr.map(({ value }) => Math.abs(value - targetValue));
  const minInd = dif.indexOf(Math.min(...dif));
  return arr[minInd].color;
}

export function ceilDate(date: Date): Date {
  const dateLeft = new Date(date.getFullYear(), date.getMonth(), 1);
  const dateRight = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  if (date.getDate() === dateLeft.getDate()) return dateLeft;
  return dateRight;
}

export function roundDayDate(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function roundDayDateWithOffset(date: Date): Date {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset);
}

export function getMonthDayLabelText(date: Date): string {
  return date.toLocaleString('en-GB', { day: 'numeric', month: 'long' });
}

export function getColorSchemeValued_Breach(withOpacity?: boolean) {
  const getExp = (x: number) => 0.001 * Math.E ** (x * 1.351788);
  const breachValues = [
    0.001,
    getExp(0.5),
    0.01,
    getExp(1.5),
    0.07,
    getExp(2.5),
    0.1,
    getExp(3.5),
    0.45,
    getExp(4.5),
    1.46,
    getExp(5.5),
    3.33,
  ];
  return lossColorScheme.map(({ color, opacity }, ind) => {
    return {
      value: breachValues[ind],
      color: withOpacity ? getColorWithOpacity(color, opacity) : color,
    };
  });
}

export function getColorByClosestValue_Breach(targetValue: number, withOpacity?: boolean): string {
  const arr = getColorSchemeValued_Breach(withOpacity);
  const dif = arr.map(({ value }) => Math.abs(value - targetValue));
  const minInd = dif.indexOf(Math.min(...dif));
  return arr[minInd].color;
}
