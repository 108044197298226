
import { MarketChangeItem } from '@/store/modules/company-tabs/market-change';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SecondTriggerWidget extends Vue {
  @Prop({ default: false }) selected!: boolean;

  get triggerData(): MarketChangeItem {
    return this.$store.getters.companyMarketChange;
  }

  get status(): MarketChangeItem['Status'] {
    return this.triggerData.Status;
  }

  get percentLoss(): MarketChangeItem['PercentChange'] {
    return this.triggerData.PercentChange;
  }
}
