
import { Component, Vue } from 'vue-property-decorator';

import FirstTriggerWidget from './triggers/FirstTriggerWidget.vue';
import SecondTriggerWidget from './triggers/SecondTriggerWidget.vue';
import ClaimProcessWidget from './triggers/ClaimProcessWidget.vue';
import StatusWidget from './StatusWidget/StatusWidget.vue';
import StatusWidgetLoss from './StatusWidget/StatusWidgetLoss.vue';
import MockTrigger from './triggers/MockTrigger.vue';
import PolicyTimelineWidget from './PolicyTimelineWidget.vue';
import LossProbabilityChart from './LossProbabilityChart.vue';
import LossConfirmationChart from './LossConfirmationChart.vue';
import CorporateAction from './CorporateAction/CorporateAction.vue';
import { LossProbabilityItem } from '@/store/modules/company-tabs/loss-probability';
import { getColorByClosestValue } from './utils';
import { MarketChangeItem } from '@/store/modules/company-tabs/market-change';
import { ClaimProcessItem } from '@/store/modules/company-tabs/claim-process';

@Component({
  components: {
    FirstTriggerWidget,
    SecondTriggerWidget,
    StatusWidgetLoss,
    MockTrigger,
    StatusWidget,
    PolicyTimelineWidget,
    LossProbabilityChart,
    LossConfirmationChart,
    CorporateAction,
    ClaimProcessWidget,
  },
})
export default class TimelinePage extends Vue {
  selectedTab: 'first' | 'second' | 'none' = 'none';

  created() {
    if (this.secondTriggerData) {
      this.selectedTab = 'second';
    } else if (this.firstTriggerData) {
      this.selectedTab = 'first';
    } else {
      this.selectedTab = 'none';
    }
  }

  selectTab(newValue: TimelinePage['selectedTab']) {
    switch (newValue) {
      case 'second':
        if (this.secondTriggerData) this.selectedTab = newValue;
        break;
      case 'first':
        if (this.firstTriggerData) this.selectedTab = newValue;
        break;
    }
  }

  get companyTitle() {
    return this.$store.getters.companyFullName as string;
  }

  get firstTriggerData(): LossProbabilityItem {
    return this.$store.getters.firstTriggerData;
  }

  get labelColor() {
    const { MaxOdds, MinOdds, RelativeOdds } = this.firstTriggerData;
    return getColorByClosestValue(MinOdds, MaxOdds, RelativeOdds);
  }

  get status(): MarketChangeItem['Status'] | LossProbabilityItem['Status'] {
    switch (this.selectedTab) {
      case 'second':
        return this.secondTriggerData?.Status || this.firstTriggerData.Status;
      case 'first':
        return this.firstTriggerData.Status;
      default:
        return this.firstTriggerData.Status;
    }
  }

  get relativeOdds(): LossProbabilityItem['RelativeOdds'] {
    return this.firstTriggerData.RelativeOdds;
  }

  get rank(): LossProbabilityItem['Rank'] {
    return this.firstTriggerData.Rank;
  }

  get secondTriggerData(): MarketChangeItem | undefined {
    if (this.firstTriggerData.Status === 'Monitoring') return undefined;
    return this.$store.getters.companyMarketChange;
  }

  get claimData(): ClaimProcessItem | undefined {
    return this.$store.getters.claimCurrentData;
  }
}
