
import { Component, Vue } from 'vue-property-decorator';
import { createChart, getRangeByYear } from './policy-chart-config';

import CanvasPopup from './CanvasPopup.vue';
import PolicyTimelineModule from '@/store/modules/company-tabs/policy-timiline';
import { CyberAlertItem } from '@/store/modules/company-tabs/cyber-alerts';
import { LossProbabilityItemPrepared } from '@/store/modules/company-tabs/loss-probability';
import { ceilDate, getColorByClosestValue } from './utils';
import { Chart } from '@/libs/chart-lib';
import { PolicyTimelineDataItem } from './policy-timeline-mock-data';
import RadioGroup from '@/components/RadioGroup.vue';

@Component({ components: { CanvasPopup, RadioGroup } })
export default class PolicyTimelineWidget extends Vue {
  private chart?: Chart;

  mounted() {
    this.chart = createChart({
      container: this.$refs.chartContainer as HTMLElement,
      startDate: this.chartStartDate,
      endDate: this.endDate,
      alertList: this.alertList,
      triggerList: this.triggerList,
      lossProbabilityList: this.lossProbabilityList,
      predictionList: this.predictionList,
      afterTriggerList: this.afterTriggerList,
      rangeYear: this.yearList[0].value,
    });
  }

  onYearSelect(value: any) {
    if (this.chart) {
      const xAxis = this.chart.xAxis;
      xAxis.setMinMax(getRangeByYear(value), true);
    }
  }

  beforeDestroy() {
    delete this.chart;
  }

  get isAdvisory() {
    return this.$store.getters.isAdvisoryUser;
  }

  get chartTitle() {
    return this.isAdvisory ? 'loss probability timeline' : 'policy timeline';
  }

  get yearList() {
    const endYear = this.endDate.getFullYear();
    let startYear = this.chartStartDate.getFullYear();
    let years = [];
    for (var i = startYear; i <= endYear; i++) {
      years.push(startYear);
      startYear++;
    }
    return years.reverse().map((el) => ({ value: el, text: String(el) }));
  }

  get policyTimelineData(): PolicyTimelineModule['data'] {
    return this.$store.getters.policyTimelineData;
  }

  get startDate() {
    return this.policyTimelineData?.PolicyStartDate;
  }

  get chartStartDate() {
    const now = new Date();
    return ceilDate(
      this.lossProbabilityList.length > 0
        ? this.lossProbabilityList[0].date
        : new Date(now.getFullYear(), 1, 1)
    );
  }

  get firstTriggerDate() {
    return this.policyTimelineData?.FirstTriggerDate;
  }

  get secondTriggerDate() {
    return this.policyTimelineData?.SecondTriggerChTimestamp;
  }

  get firstTriggerDeactivateDate() {
    return this.policyTimelineData?.FirstTriggerDeactivateDate;
  }

  get endDate() {
    const lastArrDate =
      this.lossProbabilityList.length > 0
        ? this.lossProbabilityList.slice(-1)[0].date
        : this.chartStartDate;
    let endDate;
    endDate = new Date(lastArrDate.getTime());
    if (this.predictionList.length > 0) {
      const lastPredictedDate = this.predictionList.slice(-1)[0].date;
      if (endDate.getTime() < lastPredictedDate.getTime()) {
        endDate = new Date(lastPredictedDate.getTime());
      }
    }
    endDate.setMonth(11);
    return endDate;
  }

  get alertList() {
    return this.$store.getters.companyCyberAlerts.map((item: CyberAlertItem) => ({
      date: item.ChTimestamp,
    }));
  }

  get lossProbabilityList(): PolicyTimelineDataItem[] {
    return this.$store.getters.lossProbabilityList.map(
      ({ ChTimestamp, MaxOdds, MinOdds, RelativeOdds }: LossProbabilityItemPrepared) => ({
        date: new Date(ChTimestamp),
        color: getColorByClosestValue(MinOdds, MaxOdds, RelativeOdds),
      })
    );
  }

  get afterTriggerList(): PolicyTimelineDataItem[] {
    const triggerDate = this.firstTriggerDate;
    if (triggerDate) {
      return this.lossProbabilityList.filter(({ date }) => date.getTime() >= triggerDate.getTime());
    }
    return [];
  }

  get triggerList() {
    const res = [];
    if (this.startDate) {
      res.push({
        date: ceilDate(this.startDate),
        text: 'POLICY INITIATED',
      });
    }
    if (this.firstTriggerDate) {
      res.push({
        date: this.firstTriggerDate,
        text: 'FIRST TRIGGER ACTIVATED',
      });
    }
    if (this.secondTriggerDate) {
      res.push({
        date: this.secondTriggerDate,
        text: 'LOSS CONFIRMED',
      });
    }
    if (this.firstTriggerDeactivateDate) {
      res.push({
        date: this.firstTriggerDeactivateDate,
        text: 'FIRST TRIGGER DEACTIVATED',
      });
    }
    return res.map((item) => ({ ...item, date: item.date }));
  }

  get predictionList() {
    const result: Date[] = [];
    if (this.firstTriggerDate) {
      const from = this.firstTriggerDate;
      const to = this.secondTriggerDate || this.firstTriggerDeactivateDate;
      if (to) {
        let count = 0;
        let curDate = new Date(from.getFullYear(), from.getMonth() + count, 1);
        while (curDate.getTime() <= to.getTime()) {
          result.push(curDate);
          count = count + 1;
          curDate = new Date(from.getFullYear(), from.getMonth() + count, 1);
        }
      } else {
        Array(6)
          .fill({})
          .forEach((_el, ind) => {
            result.push(new Date(from.getFullYear(), from.getMonth() + ind, 1));
          });
      }
    }
    return result.map((el) => ({ date: el, color: '#C4C4C4' }));
  }

  /*
  get alertCallbacks() {
    return mockAlertDataArr.map((el) => {
      const ComponentClass = Vue.extend(CanvasPopup);
      const cb = (params: { bottom: number; left: number }) => {
        const { bottom, left } = params;
        const instance = new ComponentClass({
          propsData: { bottom: 40 + bottom + 8, left },
        });
        instance.$mount();
        (this.$refs.chartContainer as HTMLElement).appendChild(instance.$el);
      };
      return cb;
    });
  }
  */
}
