import { Grid } from '@/libs/chart-lib/classes/Grid';
import { Chart, Ticks } from '@/libs/chart-lib/index';
import { PolicyTimelineDataItem } from './policy-timeline-mock-data';

const canvasPaddings = [10, 14, 30, 14];

const getLabelsRowData = (dateArr: Date[], yOverride: number = 0) => {
  const x: number[] = [];
  const y: number[] = [];
  dateArr.forEach((date: Date) => {
    x.push(date.getTime());
    y.push(yOverride);
  });
  return [x, y];
};

export const getRangeByYear = (year: number) => {
  const from = new Date(year, 0, 0, 0);
  const to = new Date(year, 11, 2, 0);
  return [from.getTime(), to.getTime()];
};

interface ChartData {
  container: HTMLElement;
  startDate: Date;
  endDate: Date;
  alertList: PolicyTimelineDataItem[];
  triggerList: PolicyTimelineDataItem[];
  lossProbabilityList: PolicyTimelineDataItem[];
  predictionList: PolicyTimelineDataItem[];
  afterTriggerList: PolicyTimelineDataItem[];
  rangeYear: number;
}

export function createChart({
  container,
  startDate,
  endDate,
  alertList,
  triggerList,
  lossProbabilityList,
  predictionList,
  afterTriggerList,
  rangeYear,
}: ChartData) {
  const chart: Chart = new Chart(container, getRangeByYear(rangeYear), [0, 100]);

  const { xAxis, yAxis } = chart;

  xAxis.display = false;
  xAxis.ticks.label.setOptions(true, '#00000080', 'bottom', 1.5 * 9, ['9', 'JetBrains Mono']);
  xAxis.ticks.setOptions(true, 'timestampMonthStart');
  xAxis.ticks.settickDrawOptions(6, 1, '#000000');
  xAxis.grid.setOptions(true, '#00000026', 1, [1, 0]);

  const midTimestampTicks = new Ticks(chart.xAxis.type);
  midTimestampTicks.setOptions(false, 'timestampMonthBetween', 5);
  midTimestampTicks.label.setOptions(false);
  const midGrid = new Grid(chart.xAxis.type);
  midGrid.setOptions(true, '#0000000D', 1, [1, 0]);
  midTimestampTicks.setOwnGrid(midGrid);
  chart.xAxis.addCustomTicks(midTimestampTicks);

  yAxis.display = false;
  yAxis.ticks.label.setOptions(false);

  // full width axis gray background
  chart.addPlot('policy_axis', 'policy_background', '#F0F0F099', 12, { x: 0, y: -6 - 12 });
  chart
    .addSeries('policy-axis-background', getLabelsRowData([startDate, endDate]))
    .setPlotsIds('policy_axis');

  // triggers labels
  chart.addPlot('trigger_labels', 'unicode', 0, '#000000', '');

  chart
    .findPlotById('trigger_labels')
    ?.addTooltip('trigger_tooltips', 'policy_hovered_labels')
    .setOptionsPartially({
      lineWidth: 3,
      lineColor: '#A9ABF3',
      textLine: { before: 30, length: 50 + 6, after: 0, angle: 0 },
    })
    .label.setOptions(true, '#6266F3', 'top', 0)
    .setFontOptions(11, 'JetBrains Mono', 'normal')
    .setBackgroundRectangle({
      padding: [5, 5, 5, 5],
      backgroundColor: '#EFF0FE',
      radius: 6,
      border: { size: 4, color: '#FFFFFF' },
      offset: { y: -1 },
    });

  const triggersSeries = chart
    .addSeries(
      'triggers',
      getLabelsRowData(triggerList.map(({ date }) => date)),
      triggerList.map(({ text }) => (text ? text : ''))
    )
    .setPlotsIds('trigger_labels');

  // alert labels
  chart.addPlot('alert_labels', 'unicode', 0, '#000000', '');
  chart
    .findPlotById('alert_labels')
    ?.addTooltip('alert_tooltips', 'policy_hovered_labels')
    .setOptionsPartially({
      lineWidth: 3,
      lineColor: '#ECA8A8',
      textLine: { before: 30, length: 12 + 6, after: 0, angle: 0 },
    })
    .label.setOptions(true, '#EB4040', 'top', 0)
    .setFontOptions(11, 'JetBrains Mono', 'normal')
    .setBackgroundRectangle({
      padding: [5, 5, 5, 5],
      backgroundColor: '#FCE4E4',
      radius: 6,
      border: { size: 4, color: '#FFFFFF' },
      offset: { y: -1 },
    });

  const alertsSeries = chart
    .addSeries(
      'alerts',
      getLabelsRowData(alertList.map(({ date }) => date)),
      alertList.map(() => 'ALERT')
    )
    .setPlotsIds('alert_labels');

  // loss-probability background
  chart
    .addPlot('policy_colored_back', 'policy_background_gradient', '#000', 12, { x: 0, y: -6 - 12 })
    .setOpacity(0.3);
  chart.addPlot('policy_points', 'policy_circles', '#000000', 9, { x: 0, y: -6 - 12 });
  chart
    .addSeries(
      'policy-dots',
      getLabelsRowData(lossProbabilityList.map(({ date }) => date)),
      [],
      lossProbabilityList.map(({ color }) => ({ color }))
    )
    .setPlotsIds('policy_colored_back');

  // prediction array
  chart.addPlot('policy_gray_background', 'policy_background', '#F2F2F2', 12, { x: 0, y: -6 - 12 });
  chart.addPlot(
    'policy_gray_background_pattern',
    'policy_background_pattern',
    1,
    '#C4C4C4',
    '#F2F2F2',
    9,
    { x: 0, y: -6 - 12 }
  );
  chart.addPlot('policy_red_stroke', 'policy_stroke', 1, '#FF000082', 12, { x: 0, y: -6 - 12 });

  chart
    .addSeries(
      'policy-gray-area',
      getLabelsRowData(predictionList.map(({ date }) => date)),
      [],
      predictionList.map(({ color }) => ({ color }))
    )
    .setPlotsIds(
      'policy_gray_background',
      'policy_gray_background_pattern',
      'policy_red_stroke',
      'policy_points'
    );

  // after trigger array
  if (afterTriggerList.length >= 2) {
    chart.addPlot('policy_white_background', 'policy_background', '#FFFFFF', 12, {
      x: 0,
      y: -6 - 12,
    });
    chart
      .addPlot('policy_red_pattern', 'policy_background_pattern', 1, '#FF1515', 'transparent', 9, {
        x: 0,
        y: -6 - 12,
      })
      .setOpacity(0.4);
    chart
      .addSeries(
        'policy-after-trigger',
        getLabelsRowData(afterTriggerList.map(({ date }) => date)),
        [],
        afterTriggerList.map(({ color }) => ({ color }))
      )
      .setPlotsIds(
        'policy_white_background',
        'policy_colored_back',
        'policy_red_pattern',
        'policy_red_stroke'
      );
  }

  // loss-probability dots
  chart
    .addSeries(
      'policy-dots',
      getLabelsRowData(lossProbabilityList.map(({ date }) => date)),
      [],
      lossProbabilityList.map(({ color }) => ({ color }))
    )
    .setPlotsIds('policy_points');

  chart.setCanvasPaddings(...canvasPaddings);
  chart.refresh();
  return chart;
}
