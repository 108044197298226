
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CanvasPopup extends Vue {
  @Prop() bottom!: number;
  @Prop() left!: number;
  preventClose: boolean = true;

  get routerCompanyId(): string {
    return this.$route.params.companyId;
  }

  closePopup() {
    if (this.preventClose) {
      this.preventClose = false;
      return;
    }
    this.$destroy();
    if (this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.preventClose = true;
  }
}
