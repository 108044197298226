
import { LossProbabilityItem } from '@/store/modules/company-tabs/loss-probability';
import { Component, Vue } from 'vue-property-decorator';
import { getColorSchemeValued } from './utils';

interface ChartDataItem {
  value: number;
  color: string;
  hover?: boolean;
  text?: string;
}

@Component
export default class LossProbabilityChart extends Vue {
  get triggerData(): LossProbabilityItem {
    return this.$store.getters.firstTriggerData;
  }

  get status(): LossProbabilityItem['Status'] {
    return this.triggerData.Status;
  }

  get cursorColor(): string {
    return this.status === 'Activated' ? '#FEF4F4' : '#F9F9F9';
  }

  get chartData() {
    const { MaxOdds, MinOdds, RelativeOdds } = this.triggerData;
    const valueToLabel = (value: number): string => {
      return Math.abs(value).toFixed(1).replace('.0', '') + '×';
    };
    const result: {
      value: number;
      color: string;
      hover?: boolean;
      text?: string;
    }[] = getColorSchemeValued(MinOdds, MaxOdds).map((item, ind, arr) => {
      if (
        ind === 0 ||
        ind === 2 ||
        ind === 4 ||
        ind === 8 ||
        ind === 10 ||
        ind === arr.length - 1
      ) {
        return {
          ...item,
          text: String(valueToLabel(item.value)),
        };
      }
      return item;
    });

    const dif = result.map(({ value }) => Math.abs(value - RelativeOdds));
    const minInd = dif.indexOf(Math.min(...dif));
    result[minInd] = {
      ...result[minInd],
      hover: true,
      text: minInd !== 6 ? String(valueToLabel(result[minInd].value)) : undefined,
    };
    return result;
  }

  get xGap() {
    return (616.5 - 61.5) / (this.chartData.length - 1);
  }
}
