
import { MarketChangeItem } from '@/store/modules/company-tabs/market-change';
import { Component, Vue } from 'vue-property-decorator';
import { getMonthDayLabelText } from '../utils';

@Component
export default class StatusWidgetLoss extends Vue {
  get triggerData(): MarketChangeItem {
    return this.$store.getters.companyMarketChange;
  }

  get daysRemain(): MarketChangeItem['CalculationPeriodDaysRemaining'] {
    return this.triggerData.CalculationPeriodDaysRemaining;
  }

  get percentLoss(): MarketChangeItem['PercentChange'] {
    return this.triggerData.PercentChange;
  }

  get startDate(): string {
    const date = this.triggerData.PercentPeriodStartDate;
    if (date) return getMonthDayLabelText(date);
    return '-';
  }

  get curDate(): string {
    const date = this.triggerData.PercentPeriodEndDate;
    if (date) return getMonthDayLabelText(date);
    return '-';
  }
}
